<template>
  <nav class="flex justify-center text-2xl">
    <ul class="flex text-o_purple-200 font-semibold items-end capitalize mr-6  list-none">
      <li class="rounded-sm  py-1 mr-5 hover:underline" v-for="item in nav_items" :key="item.name">
        <router-link :to="{name: item.path}">
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </nav>

</template>

<script>
export default {
  name: 'NavBar',
  data: function () {
    return {
      nav_items: [
        {
          path: 'About',
          name: 'about',
          label: '簡介'
        },
        {
          path: 'Platform',
          name: 'platform',
          label: '資訊介面'
        },
        {
          path: 'Records',
          name: 'records',
          label: '操作紀錄'
        },
        {
          path: 'TermAndBlockchain',
          name: 'TermAndBlockchain',
          label: '詞彙與區塊鏈'
        },
        {
          path: 'Check',
          name: 'Check',
          label: '查驗'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  //li:hover {
  //  list-style: disc;
  //}
</style>
